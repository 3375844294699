.becomeReviewer .inputWrapper {
  display: flex;
  flex-direction: column;
}

.becomeReviewer form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.becomeReviewer h3 {
  display: flex;
  font-size: 1.3rem;
  align-items: center;
  gap: 10px;
}

.becomeReviewer textarea {
  resize: vertical;
}

.becomeReviewer button {
  margin-right: auto;
  font-size: 1rem;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  outline-color: transparent;
}

.becomeReviewer .applicationStatus {
  margin-bottom: 20px;
}

.becomeReviewerWrapper {
  padding: 20px;
}

.becomeReviewer .applicationStatus span {
  font-size: 1rem;
}

.rejectionsContainer {
  padding: 20px;
  background-color: rgba(239, 239, 239, 0.3);
  border-radius: 5px;
  margin: 0 10px;
}
.rejectionsContainer h3 {
  margin-bottom: 5px;
}

.rejectionsContainer .form-control {
  background-color: transparent !important;
}

.rejectionsContainer .rejectedApplicationWrapper:not(:first-of-type) {
  border-top: 1px solid #d3d3d3d3;
  padding-top: 10px;
  margin-top: 20px;
}

.rejectionsContainer .rejectedApplicationWrapper p {
  margin-bottom: 0px;
}
