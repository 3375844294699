table {
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-width: 720px;
}
.singleRowTable table {
  min-width: unset;
}

table * {
  position: relative;
}
table td,
table th {
  padding-left: 8px;
  padding: 15px;
}
table thead tr {
  height: 60px;
  background: var(--main08);
  color: white;
  font-size: 16px;
  white-space: nowrap;
}
table tbody tr {
  font-size: 14px;
}
table tbody tr {
  height: 48px;
}
tbody tr:nth-child(even) {
  background-color: #d3d3d32e;
}
table tbody tr:last-child {
  border: 0;
}
table td {
  text-align: left;
  max-width: 280px;
  vertical-align: sub;
}
table td.l,
table th.l {
  text-align: right;
}
table td.c,
table th.c {
  text-align: center;
}
table td.r,
table th.r {
  text-align: center;
}

table tbody .tableBtn {
  background-color: var(--main05);
  color: white;
  transition: all 0.1s ease;
  transform: scale(1);
  color: white;
  border-radius: initial;
  /* padding: 5px; */
  font-size: 14px;
  white-space: nowrap;
  border-radius: 5px;
  width: fit-content;
}

table tbody .tableBtn:hover {
  background-color: var(--main800);
  color: white;
}

.table-container {
  overflow: auto;
}

@media screen and (max-width: 970px) {
  .table-container {
    overflow: unset;
  }
  table {
    display: block;
    min-width: fit-content;
  }
  table > *,
  table tr,
  table td,
  table th {
    display: block;
  }
  table thead {
    display: none;
  }
  table tbody tr {
    height: auto;
    padding: 8px 0;
  }
  table tbody tr td {
    padding: 5px;
    margin-bottom: 12px;
    max-width: unset;
  }
  table tbody tr td:last-child {
    margin-bottom: 0;
  }
  td:not(:has(> a))::before {
    content: attr(data-cell) ": ";
    font-weight: 600;
    text-transform: capitalize;
  }

  .truncateTextToSingleLine {
    vertical-align: bottom;
    display: -webkit-inline-box;
  }
}

.downloadBtn {
  text-align: left;
  font-size: 0.9rem;
  padding: 0px;
  background: transparent;
  border: 0;
  color: #666;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 5px;
}

.downloadBtn:hover {
  opacity: 0.8;
}

.deleteReviewerBtn {
  cursor: pointer;
  color: rgb(255, 0, 0);
  width: 55px;
}

.deleteReviewerBtn:hover {
  opacity: 0.8;
}
