html body * {
  font-family: neue, Helvetica, Roboto, Arial, sans-serif !important;
}

body {
  scrollbar-gutter: stable;
}
:root {
  font-size: 16px;
}
p {
  margin-bottom: 5px;
}

input,
select,
textarea {
  outline: none;
  font-size: 0.93rem;
  padding: 5px 5px;
  border-radius: 4px;
  color: var(--textColor);
  border: 1px solid var(--main05);
}

nav.mainNav * {
  font-family: "Nunito", sans-serif !important;
  font-weight: 500;
}

.mainSearchFrom span {
  font-family: "Nunito", sans-serif !important;
}

html body :is(input, select, button, textarea) {
  font-family: "Nunito", sans-serif !important;
}

.btn-outline-success {
  --bs-btn-color: var(--main800);
  --bs-btn-border-color: var(--main800);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--main800);
  --bs-btn-hover-border-color: var(--main800);
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--main800);
  --bs-btn-active-border-color: var(--main800);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--main05);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--main05);
  --bs-gradient: none;
}
.box-shadow {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.btn:focus-visible {
  color: white !important;
  opacity: 0.9;
}

.auxillaryWrapper textarea {
  flex: 1 !important;
}

input:focus,
select:focus,
textarea:focus {
  border: 1px solid var(--main800);
}

.innerDiv {
  padding: 10px 2rem !important;
}

input::placeholder {
  color: var(--textMuted);
}

ul {
  list-style: none;
  padding-left: 0px;
}

.mainBtn {
  background-color: var(--main800) !important;
  color: white;
  transition: all 0.1s ease;
  transform: scale(1);
  color: white;
  min-width: 140px;
}

.mainBtn[disabled] {
  opacity: 0.7;
  color: white;
}

.menus {
  height: fit-content;
}

.mainBtn:hover {
  background-color: var(--main800);
  color: white !important;
  opacity: 0.8;
}

.mainBtn:active {
  background-color: var(--main800) !important;
  color: white !important;
  opacity: 0.5;
}

.marginRightAuto {
  margin-right: auto;
}

.mainWrapper {
  width: 90%;
  margin: 0 auto;
}

.mainWrapper > main {
  display: flex;
  flex-wrap: wrap;
}

.flex1 {
  flex: 1;
}

body {
  background-color: var(--main01);
}

.main800Background {
  background-color: var(--main800);
}

.main05Background {
  background-color: var(--main05);
}

.card-shadow {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

main {
  background-color: #d3d3d32e;
  min-height: calc(100vh - 256px);
  padding: 1.5rem;
}

article a {
  text-decoration: none;
}

.contentfulDiv {
  background-color: white;
  padding: 1.5rem 10px;
}
.contentfulDiv h2 {
  font-size: 1.3rem;
  margin-bottom: 6px;
  font-weight: bold;
  margin: 0 auto;
  margin-bottom: 10px;
}
.contentfulDiv p {
  font-weight: 400;
  font-size: 0.7rem;
  text-align: center;
  margin: 0 auto;
}

.contentfulDiv *:not(h2) {
  font-size: 0.85rem !important;
}

.contentfulDiv > div * {
  font-weight: 400;
  font-size: 0.9rem;
  text-align: center;

  margin: 0 auto;
}

.contentfulDiv > div {
  width: 90%;
  margin: 0 auto;
}

.positionSticky {
  position: sticky;
  top: 20px;
}

.navHeader {
  flex: 1;
  overflow: hidden;
}

.card {
  border-radius: 5px;
  padding: 0.7rem;
  color: var(--textColor);
  font-size: 0.8rem;
  line-height: 1rem;
}

.card h2 {
  font-size: 1.2rem;
}

footer {
  background-color: var(--main800);
}

.boardHeader {
  background-color: var(--main05);
  padding: 10px;
  color: white;
  font-weight: bold;
  border-radius: 3px;
  font-size: 1.3rem;
}

.boardMemberContainer a {
  color: var(--textColor);
}

a {
  color: var(--textColor);
}

.hamburgerMenu {
  display: none;
  background-color: var(--main800);
  border-radius: 5px;
  padding: 10px;
  width: 40px;
  outline-color: transparent;
  border: none;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.hamburgerMenu rect {
  fill: white;
  transition: all 0.3s ease;
  transition-delay: 0.2s;
  transform-origin: center;
}
.mainInputWrapper {
  width: calc(100% - 166px - 269px);
}

.hamburgerMenu.active .line {
  transition: y 300ms ease-in, rotate 300ms ease-in 300ms, opacity 0ms 300ms;
}

.hamburgerMenu.active :is(.top, .bottom) {
  y: 45;
}

.hamburgerMenu.active .top {
  rotate: 45deg;
}

.hamburgerMenu.active .middle {
  opacity: 0;
}

.hamburgerMenu.active .bottom {
  rotate: -45deg;
}

form :is(input, select, textarea) ~ span {
  display: none;
}
form
  :is(
    input[focus="true"]:invalid,
    select[focus="true"]:invalid,
    textarea[focus="true"]:invalid
  )
  ~ span {
  display: block;
}

form
  :is(
    input[focus="true"]:invalid,
    select[focus="true"]:invalid,
    textarea[focus="true"]:invalid
  ) {
  border-color: red;
}

form.authForm .inputWrapper,
form.editProfileForm .inputWrapper,
form.changePasswordForm .inputWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

form.editProfileForm .inputWrapper span,
form.changePasswordForm .inputWrapper span {
  color: red;
}

.gap10 {
  gap: 10px;
}

.gap15 {
  gap: 15px;
}

@media screen and (max-width: 1230px) {
  .mainContainer {
    flex-wrap: wrap;
  }

  footer .mainNavWrapper {
    display: none !important;
  }

  .mainWrapper {
    width: 90%;
  }
  main .hamburgerMenu {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 99999;
    width: 100%;
  }

  .userRole {
    display: none;
  }

  .navUserInfo {
    justify-content: center;
  }

  .mainContainer .sidebarLeftContainer {
    z-index: 9999;
    top: 5px;
    max-height: 95vh;
    overflow: auto;
    transition: all 0.5s ease;
    border-color: 2px solid var(--main800);
    width: 100%;
  }

  .mainContainer .sidebarLeftContainer:has(.sidebarLeft.showMenu) {
    background-color: var(--main800);
    padding: 15px;
    border-radius: 5px;
  }

  .mainContainer .sidebarLeft {
    margin-top: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: none;
  }

  .mainContainer .sidebarLeft.showMenu {
    display: block;
  }

  .mainContainer .sidebarRight {
    width: 100%;
    margin-bottom: calc(20px + 0.5rem);
    flex: unset;
  }
  .mainContainer .sidebarRight .subscibeSidebar {
    display: none;
  }

  .mainWrapper > main {
    display: block;
  }
  .mainWrapper > main > .menus {
    background-color: var(--main800);
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 10px;
    z-index: 999;
    border: 1px solid white;
  }
  .menus > div:last-child.active {
    display: none;
  }
}

@media screen and (max-width: 1150px) {
  .mainInputWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 630px) {
  .inputContainer {
    flex-wrap: wrap;
  }
  .inputContainer :is(input, select, textarea):not(input[type="checkbox"]) {
    min-width: calc(50% - 20px);
  }
  .mainWrapper {
    width: 100%;
  }
  nav .hamburgerMenu {
    display: flex;
  }
}

@media screen and (max-width: 770px) {
  nav .hamburgerMenu {
    display: flex;
  }

  .mainNav:has(ul.active) .hamburgerMenu {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 99999999999999999;
  }
  body .mainNav ul li:not(:first-child) {
    font-size: 1.1rem !important;
  }

  body .mainNav ul {
    position: fixed;
    top: 0;
    right: 300px;
    bottom: 0;
    flex-direction: column;
    gap: 10px;
    display: none !important;
    background-color: var(--main800);
    transition: right 0.3s ease;
    z-index: 9999999;
    width: 300px;
    border-left: 1px solid white;
  }

  body .mainNav ul.active {
    display: flex !important;
    right: 0px;
  }

  body .mainNav ul.active ::after {
    height: auto !important;
  }

  body .mainNav ul.active li {
    margin: 0px !important;
    width: 100%;
    text-align: center;
    font-size: 30px;
  }
}

.download {
  cursor: pointer;
  margin-left: 5px;
  vertical-align: middle;
  border-left: 1px solid var(--main05);
  margin-left: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.uploadArticlesDescription {
  display: flex;
  /* gap: 40px; */
  flex-wrap: wrap;
}

.uploadArticlesDescription ul {
  list-style: circle;
  margin-left: 1.5rem;
}

.uploadArticlesDescription span {
  font-weight: bold;
}
.animate-issue {
  animation-name: scale-in;
  animation-duration: 1s;
}
label[for="keywords"] {
  max-width: unset !important;
}
.inputWrapper textarea {
  min-height: 55px;
  max-height: 200px;
  resize: vertical;
}

.authorInputContainer .inputWrapper textarea,
.authorInputContainer .inputWrapper input {
  width: 100%;
}

.characterCounter {
  position: absolute;
  top: 100%;
  right: 0;
}

:is(label, select) span {
  color: red;
}

.applicationStatus .pending {
  background-color: var(--statusPending);
  color: white;
  padding: 7px 15px;
  border-radius: 15px;
}

.rejected {
  background-color: var(--statusRejected);
  color: white;
  padding: 7px 15px;
  border-radius: 15px;
}
.outline-btn {
  background-color: transparent !important;
  color: var(--main800) !important;
  border: 1px solid var(--main800) !important;
}

.mainBtn-outlined:hover {
  background-color: var(--main05) !important;
}

.filepond--file-info-sub {
  display: none;
}
.accepted {
  background-color: var(--statusAccepted);
  color: white;
  padding: 7px 15px;
  border-radius: 15px;
}

.quillContainer .quill .ql-editor {
  min-height: 150px;
}

.quillContainer .quill {
  border-radius: 5px;
  overflow: hidden;
}
.quillContainer .ql-toolbar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.quillContainer .ql-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.quillContainer {
  flex: 1;
}

.rankingContainer {
  padding: 20px;
  background-color: var(--main08);
  border-radius: 5px;
}
.rankingContainer label {
  color: white;
}
.rankingContainer .rank {
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
  margin-right: 20px;
}


.manuscript .uploadedArticleContainer {
  margin: 0 !important;
}

.reviewerRefused input[type="checkbox"]:disabled {
  background-color: red;
}
.circleUl {
  list-style-position: inside;
  list-style-type: disc;
}

.rankingContainer .inputWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  justify-content: start;
}

.rankingContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.truncateTextToSingleLine {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  max-width: 130px;
}
textarea {
  flex: unset !important;
  max-height: 500px !important;
}

input[type="checkbox"],
input[type="radio"] {
  width: 17px;
  height: 17px;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid var(--main05);
  appearance: none;
  border-radius: 4px;
  cursor: pointer;
  aspect-ratio: 1;
  transition: all 0.3s ease-in;
  padding: 0 !important;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  background-color: var(--main05);
  border: 1px transparent;
  box-shadow: 0 2px 4px 0 var(--main01);
  background-image: url(./Assets/Images/checkmark.png);
  background-size: 60%;
}

.errorPage {
  text-align: center;
}

.errorPage h1 {
  font-size: 3rem;
  color: var(--main08);
  margin: 0;
  font-weight: 600;
}

.errorPage h5 {
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  color: var(--main08);
  margin-bottom: 22px;
}

.errorPage p {
  font-size: 0.9rem;
  color: gray;
}

.errorPage button {
  background-color: var(--main08);
  color: white;
  transition: all 0.1s ease;
  transform: scale(1);
  min-width: 140px;
  margin: 10px 0;
}

.errorPage button:hover {
  background-color: var(--main08);
  color: white;
  opacity: 0.8;
}

#articleInformation {
  flex: 1 !important;
}

@keyframes scale-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 630px) {
  .inputContainer {
    flex-wrap: wrap;
  }
  .inputContainer :is(input, select, textarea):not(input[type="checkbox"]) {
    min-width: calc(50% - 20px);
  }
  .mainWrapper {
    width: 100%;
  }
  nav .hamburgerMenu {
    display: flex;
  }
}

@media screen and (max-width: 630px) {
  .boardMemberContainer {
    flex-wrap: wrap;
  }
  .boardMemberContainer img {
    order: -1;
  }
}


img.profile-photo {
  width: 50px;
  object-fit: cover;
  aspect-ratio: 1;
  border-radius: 50%;
}

.changeStatusBtn {
  text-transform: none;
  color: var(--main);
  transition: 0.5s all ease-in;
}

.changeStatusBtn:hover {
  border-bottom: 1px solid;
}
