.rejectedInfo {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.rejectedInfo > div {
  padding: 10px 10px;
}

/* .rejectedInfo > :first-child {
  border-bottom: 1px solid lightgray;
  font-weight: 600;
} */

.rejectedInfo div > span {
  font-size: 12px;
  color: gray;
}

.rejectedInfo div > p {
  font-size: 16px;
  color: #353232;
  margin-top: 8px;
}

.highlightedFileContainer {
  border-radius: 5px;
  padding: 20px;
}

.applicationResultContainer {
  gap: 40px;
}

.applicationResultContainer > div:not(div:nth-child(2)) {
  min-width: fit-content;
}

.applicationResultContainer > div:nth-child(2) p {
  white-space: pre-wrap;
}

.publishFileContainer .inputWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.reviewerContainer {
  max-height: 402px;
  overflow: auto;
}

.reviewerContainer label span {
  color: black;
}

.reviewerContainer label {
  user-select: none;
  cursor: pointer;
}

.reviewerContainer > label:not(:last-child) {
  border-bottom: 1px solid lightgray;
  padding-bottom: 8px;
}

.inputContainer label {
  user-select: none;
  cursor: pointer;
}

.auxModal .inputContainer input[type="checkbox"] {
  flex: 0;
}
@media screen and (max-width: 630px) {
  .applicationResultContainer {
    flex-direction: column;
    gap: 20px;
  }

  .applicationResultContainer > div:nth-child(2) {
    flex: 1;
  }
}

.card {
  box-shadow: var(--main01) 0px 2px 8px 0px;
  padding: 10px;
  max-width: 100%;
  border: none !important;
  border-radius: 2px !important;
  color: #495057 !important;
}

span.counter-value {
  color: var(--main800);
}

.reviewerDetails h2 {
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--textColor);
  margin-bottom: 3px;
}

.reviewerDetails > span {
  font-style: italic;
  margin-bottom: 5px;
}

.reviewerDetails .contact {
  margin-top: 4px;
  gap: 3px;
}

.reviewerDetails .contact span {
  font-weight: 600;
}

.auxModalBody .reviewsMainWrapper {
  border: 0;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 3px;
}

.auxModalBody .reviewContainer.open i {
  transform: rotate(90deg);
}

.auxModalBody .reviewContainer.open .accordionBody {
  grid-template-rows: 1fr;
}

.auxModalBody .reviewContainer.open .editorComment {
  border-top: 0;
  padding-top: 0;
}

.auxModalBody .reviewContainer .accordionHeader {
  background-color: rgba(239, 239, 239, 0.3);
  text-transform: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auxModalBody .reviewContainer .accordionHeader.rejected {
  background-color: #e93a3ae3;
  border-radius: 0;
}

.auxModalBody .reviewContainer .accordionHeader:not(:first-child) {
  border-top: 1px solid lightgray;
}

.auxModalBody .reviewContainer:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.auxModalBody > div.inputContainer {
  max-height: 445px;
  overflow: auto;
}

.accpetedReviewsColor {
  background-color: #cfcfcf;
  width: 10px;
  display: inline-block;
  aspect-ratio: 1;
}

.rejectedReviewsColor {
  background-color: #e93a3ae3;
  width: 10px;
  display: inline-block;
  aspect-ratio: 1;
}

.submittedArticleTable {
  margin: 20px;
}
.submittedArticleTable > p {
  margin-left: 11px;
  text-transform: uppercase;
}

.rejectedInfo {
  text-align: center;
}
