.submitArticleNav {
  padding: 20px;
  background-color: var(--main800);
  border-radius: 5px;
}

.submitArticleNav ul {
  display: flex;
}

.submitArticleNav ul > li {
  flex: 1;
  text-align: center;
}

.submitArticleNav ul > li > a {
  color: white;
  text-decoration: none;
  position: relative;
  isolation: isolate;
}

.submitArticleNav ul > li > a.active {
  color: var(--main800);
}
.submitArticleNav ul > li > a.active::after {
  content: "";
  background-color: white;
  position: absolute;
  min-height: 78px;
  border-radius: 5px 5px 0px 0px;
  right: 0;
  top: -22%;
  left: 0;
  z-index: -1;
}

.submitArticleNav ul > li > a h3 {
  font-weight: bold;
  font-size: 16px;
}

.submitArticleNav ul > li > a {
  display: block;
}

.submitArticleForm {
  margin-top: 20px;
  padding: 0px 20px;
}

.submitArticleForm .inputWrapper {
  display: flex;
  flex-direction: column;
}

.submitArticleForm > form .authorInputContainer > div.d-flex .inputWrapper {
  flex: 1;
}

.submitArticleForm > form .authorInputContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border: 1px solid var(--main01);
  border-top: 0px;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.submitArticleForm .inputWrapper :is(input, select, textarea) {
  flex: 1;
}

.submitArticleForm .inputWrapper label {
  max-width: 150px;
  min-width: 150px;
}

.submitArticleForm form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.submitArticleForm .authorInformationForm .authorHeader {
  background-color: var(--main05);
  justify-content: space-between;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.authorHeader :is(input[type="checkbox"], input[type="radio"]) {
  flex: unset !important;
}

.submitArticleForm .authorInformationForm > div:not(:last-of-type) {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.submitArticleForm .authorInformationForm .authorHeader .inputWrapper {
  flex: 0;
  min-width: 200px;
}
.submitArticleForm .authorInformationForm .authorHeader .inputWrapper {
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.submitArticleForm .authorInformationForm .authorHeader .inputWrapper select {
  flex: 0;
  min-width: 80px;
}

.submitArticleForm .authorInformationForm input[disabled] {
  background-color: rgb(242, 242, 242);
  opacity: 0.8;
}

.submitArticleForm .correspondingAuthorBadge {
  background-color: var(--main800);
  color: white;
  display: block;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 10px;
  max-width: 180px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.correspondingAuthor h3 {
  border-top-left-radius: 0;
}

.submitArticleForm .authorInformationForm .correspondingAuthorHeader {
  border-top-left-radius: 0px;
}

.submitArticleForm .authorInformationForm .authorHeader .inputWrapper label {
  min-width: unset;
  max-width: unset;
  font-weight: 600;
  color: white;
}

.submitArticleForm .authorInformationForm .authorHeader button {
  background-color: transparent;
  color: var(--main800);
  font-size: 30px;
  outline: transparent;
  border: none;
}

.submitArticleForm .authorInformationForm .authorHeader button[disabled] {
  color: lightgray;
  opacity: 0.5;
}

.submitArticleForm .authorInformationForm .authorHeader h3 {
  margin-bottom: 0px;
  font-size: 1.3rem;
}

.submitArticleForm form textarea {
  min-height: unset;
  max-height: unset;
  resize: vertical;
  min-height: 30px;
}

.submitArticleForm :is(#nextBtn, #prevBtn) {
  font-size: 15px;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  padding: 8px 10px;
}

.submitArticleForm form #addNewAuthor {
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid var(--main800);
  background-color: transparent;
  color: var(--main800);
  font-size: 1.1rem;
  position: relative;
  top: -10px;
  padding: 8px 10px;
  transition: all 0.5s ease;
}
.submitArticleForm form #addNewAuthor:hover {
  background-color: var(--main800);
  color: white;
}

.articleInfoAuthorContainerwrapper {
  margin-top: 20px;
}

/* .submitArticleForm form #nextBtn {
  margin-left: auto;
} */

.articleInfoContainer {
  margin: 0 20px;
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  border-bottom: 1px solid #d3d3d3;
  padding: 10px 20px;
}

.articleInfoContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.authorInfoWrapper {
  flex-wrap: wrap;
}

.articleInfoContainer span {
  min-width: 170px;
  max-width: 170px;
  font-size: 0.9rem;
}

.articleInfoContainer b {
  font-size: 1.03rem;
  font-weight: 500;
  word-break: break-word;
}

.uploadedArticleContainer > span {
  display: flex;
  flex-wrap: wrap;
}

.uploadedArticleContainer .download {
  border-right: none;
}

.articleInfoAuthorContainer .authorInfoWrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  border-bottom: 1px solid #d3d3d3;
  padding: 10px 20px;
}

.articleInfoAuthorContainer .authorInfoWrapper:last-child {
  border: none;
}
.articleInfoAuthorContainer .authorInfoWrapper span {
  min-width: 170px;
  max-width: 170px;
  font-size: 0.9rem;
}

.articleInfoAuthorContainer .authorInfoWrapper > b {
  word-break: break-word;
}

.articleInfoAuthorContainer h3 {
  text-align: center;
  font-size: 0.98rem;
  font-weight: bold;
  padding: 10px;
  background: var(--main01);
  border-radius: 5px;
}

.articleInfoAuthorContainer {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin: 0px 20px;
  padding: 10px;
  margin-top: 20px;
}

.submitArticleForm {
  margin-bottom: 20px;
}
.totalAuthorNr {
  margin-left: 1rem;
  font-size: 1rem;
  margin-top: 20px;
}

.articleInfoAuthorContainer:last-child {
  margin-bottom: 20px;
}
.uploadedArticleContainer {
  margin: 0px 20px;
}

.uploadedArticleContainer:first-of-type {
  margin-top: 20px;
}
.submitArticleNav a[disabled] {
  pointer-events: none;
  color: lightgray;
}

.authorHeader .inputWrapper:first-of-type label {
  cursor: pointer;
}

.deleteAuthorModalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.deleteAuthorModalBody button:first-of-type {
  background-color: rgb(225 21 21);
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.deleteAuthorModalBody button:first-of-type:hover {
  opacity: 0.8;
}

.deleteAuthorModalBody button:last-of-type {
  background-color: transparent;
  outline: none;
  border: none;
}

.previousArticleFiles h3 {
  font-size: 20px;
}

.previousArticleFiles {
  border: 1px solid var(--main800);
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

@media screen and (max-width: 815px) {
  .submitArticleNav ul {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .submitArticleNav ul a::after {
    border-radius: 5px !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

@media screen and (max-width: 815px) {
  .submitArticleForm .authorHeader {
    flex-wrap: wrap;
  }
  .submitArticleForm .authorHeader .inputWrapper {
    order: 10;
    margin-top: 10px;
    flex: none !important;
    width: 100%;
  }
}
