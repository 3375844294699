.journalMenu {
    display: flex;
    flex-direction: column;
  }
  
  .journalMenuContainer {
    flex: 1;
    background-color: white;
    padding: 0.5rem;
    border-radius: 5px;
  }
  
  .journalMenuContainer nav a {
    color: var(--main800);
    border-radius: 4px;
    padding: 0.3rem 0.2rem;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all 0.1s ease-in;
    font-size: 0.8rem;
  }
  
  .journalMenuContainer nav a:hover {
    background-color: var(--main05);
    color: white;
  }
  
  .journalMenuContainer nav a.active {
    background-color: var(--main800);
    color: white;
  }
  
  .journalMenuContainer nav a.active::before {
    transition: all 0.1s ease-in;
    background-color: white;
  }
  .journalMenuContainer span {
    font-size: 1.2rem;
    display: block;
    margin-bottom: 10px;
  }
  
  .journalMenuContainer nav a::before {
    content: "";
    border-radius: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: var(--main05);
  }
  
  .journalMenuContainer nav a:hover {
    background-color: var(--main05);
    color: white;
  }

  .journalMenuContainer h2{
    font-size: 0.8rem;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--main05);
  }