.modalDiv {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reject-comment-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 999;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.9s ease-in-out;
}

.reject-comment-modal.show {
  display: flex;
  opacity: 1;
}

.reject-comment-modal .modalContent {
  background-color: #fff !important;
  padding: 20px;
  border-radius: 4px !important;
  max-width: 800px !important;
  text-align: center;
  box-shadow: var(--main01) 0px 2px 8px 0px;
  transform: translateY(-20px);
  margin: 0 10px;
  height: auto;
}

.modalContent.auxModal {
  height: auto;
  width: 90%;
  max-width: 650px;
  padding-bottom: 0px;
}

.auxModalBody {
  display: flex;
  flex-direction: column;
}

.auxModalBody .inputWrapper label {
  display: block;
}

.auxModalBody .inputWrapper textarea {
  width: 100%;
}
.reject-comment-modal .inputWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.reject-comment-modal .inputWrapper textarea {
  min-height: 80px;
  max-height: 150px;
}


.zIndex {
  z-index: 9999999999;
}