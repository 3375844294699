.unauthorizedResponse {
  height: 57vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.unauthorizedResponse i {
  margin-bottom: 22px;
  font-size: 3rem;
}

.unauthorizedResponse h4 {
  font-size: 1.4rem;
  letter-spacing: 0.5px;
}

.unauthorizedResponse p {
  font-size: 0.9rem;
  color: gray;
}

.unauthorizedResponse .logoutLink {
  margin-top: 20px;
  text-decoration: none;
  font-weight: 600;
  color: var(--main800);
}

.unauthorizedResponse .logoutLink:hover {
  opacity: 0.8;
}
