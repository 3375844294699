.modalContent {
  background-color: #fff !important;
  padding: 20px;
  border-radius: 4px !important;
  box-shadow: var(--main01) 0px 2px 8px 0px;
  margin: 0 10px;
  text-align: start;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.modalContent > div:nth-of-type(1) {
  overflow: auto;
  height: 100%;
}

.modalContent > div:nth-of-type(1) textarea:not(#alertComment) {
  flex: 1 !important;
}

* ::-webkit-scrollbar {
  width: 10px;
}

* ::-webkit-scrollbar-track {
  background: transparent;
}

* ::-webkit-scrollbar-thumb {
  background: var(--main01);
  border-radius: 5px;
}

* ::-webkit-scrollbar-thumb:hover {
  background: var(--main05);
}

.modal-footer {
  border-top: 1px solid #8080806e;
  padding: 15px 0 5px 0;
  text-align: right;
  gap: 10px;
}

.modal-footer button {
  background: transparent;
  border: 0;
  color: #666;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  padding: 0 1rem;
  text-transform: uppercase;
}

button.btn-close {
  position: absolute;
  right: 28px;
  top: 31px;
  color: white;
}
.inputContainer {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.inputContainer label {
  width: 130px;
  font-size: 0.8rem;
  display: block;
}
.inputContainer textarea,
.inputContainer input:not([type="radio"]),
.inputContainer button,
.inputContainer .reviewerContainer {
  font-size: 0.9rem;
  padding: 10px;
  flex: 1;
}

.inputContainer .reviewerContainer {
  gap: 10px;
}

.inputContainer .reviewerContainer label {
  min-width: 150px;
  width: fit-content;
}

.inputContainer label {
  margin-top: 10px;
  white-space: nowrap;
}

.inputContainer input[type="checkbox"] {
  margin-right: 10px;
}

.reviewersNo {
  font-size: 11px;
  color: #595858;
  margin-top: 5px;
}

.modalDiv .inputContainer textarea {
  max-height: 150px;
  min-height: 55px;
}

.modalDiv .inputContainer .sendAlertTo {
  white-space: nowrap;
}

.modalContent > .modalHeader > svg:hover,
.modal-footer > button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.quillContainer .quill .ql-editor {
  min-height: 150px;
}

.quillContainer .quill {
  border-radius: 5px;
  overflow: hidden;
}
.quillContainer .ql-toolbar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.quillContainer .ql-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.quillContainer {
  flex: 1;
}

.rankingContainer {
  padding: 20px;
  background-color: var(--main08);
  border-radius: 5px;
}
.rankingContainer label {
  color: white;
}

.rankingContainer .rank {
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
  margin-right: 20px;
}

.rankingContainer .inputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 1;
}

.rankingContainer .inputWrapper label {
  white-space: nowrap;
}

.rankingContainer {
  gap: 10px;
}

.stepDividerContainer .ql-editor[contenteditable="false"] {
  background-color: rgba(239, 239, 239, 0.3);
}

.stepDividerContainer.readonly .reviewDividerHeader {
  background-color: var(--main05);
}
.stepDividerContainer.readonly .rankingContainer {
  background-color: var(--main05);
}

.rankingContainer {
  margin-bottom: 20px;
}

.rankingContainer label {
  cursor: pointer;
  user-select: none;
}

.stepDividerContainer:first-child {
  margin-top: 20px !important;
}
.stepDividerContainer:not(:only-of-type) {
  margin-top: 5px;
}

.stepDivider {
  padding: 0;
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: all 200ms;
}

.stepDivider > div {
  min-height: 0;
}

.stepDividerContainer.open .stepDivider {
  padding: 0px 0px 20px 0px;
  grid-template-rows: 1fr;
}

.toggleStep {
  width: 100%;
  text-align: left;
  outline-color: transparent;
  border: 0;
  padding: 0;
}

.reviewDividerHeader {
  background-color: var(--main800);
  padding: 10px;
  margin-bottom: 0;
  color: white;
  border-radius: 5px;
}
.reviewDividerWrapper {
  border: 1px solid var(--main01);
  border-top: none;
  padding: 0px;
  transition: 200ms all;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.stepDividerContainer.open .stepDivider .reviewDividerWrapper {
  padding: 20px 0px;
}
.stepDividerContainer.open .reviewDividerHeader {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.stepDividerContainer .bi-chevron-right::before {
  transition: all 200ms;
  margin-right: 10px;
  transform: rotate(0deg);
}
.stepDividerContainer.open .bi-chevron-right::before {
  transform: rotate(90deg);
}
.modalHeader {
  margin-bottom: 0;
  padding: 10px;
  background-color: transparent;
  color: var(--main800);
  border-bottom: 1px solid #8080806e;
  font-weight: bold;
}

.modalHeader svg {
  width: 30px !important;
  height: 30px !important;
}
.delete-confirmation-modal,
.session-expired-modal,
.edit-profile-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 999;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.9s ease-in-out;
}

.delete-confirmation-modal.show,
.session-expired-modal.show,
.edit-profile-modal.show {
  display: flex;
  opacity: 1;
}

.modal-content {
  background-color: #fff !important;
  padding: 20px;
  border-radius: 4px !important;
  max-width: 335px !important;
  min-width: 300px !important;
  text-align: center;
  box-shadow: var(--main01) 0px 2px 8px 0px;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;
  margin: 0 10px;
}

.edit-profile-modal .modal-content {
  background-color: #fff !important;
  padding: 20px;
  border-radius: 4px !important;
  max-width: 525px !important;
  min-width: 300px !important;
  text-align: center;
  box-shadow: var(--main01) 0px 2px 8px 0px;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;
  margin: 0 10px;
}

.modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.delete-icon {
  padding: 20px;
  /* background-color: var(--main800); */
  width: fit-content;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.delete-confirmation-modal.show .modal-content,
.session-expired-modal.show .modal-content,
.edit-profile-modal.show .modal-content {
  opacity: 1;
  transform: translateY(0);
}

.user-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.change-password-link {
  display: block;
  cursor: pointer;
  font-size: 13px;
  color: var(--bs-blue);
  text-decoration: underline;
}

.search-bar {
  display: flex;
  align-items: center;
}

.search-bar input {
  border: none;
  padding: 0 10px !important;
}

.articleDetailsContainer .inputContainer {
  flex-direction: column;
  margin-top: 5px !important;
  align-items: stretch;
}

.articleDetailsContainer ~ .inputContainer {
  flex-direction: column;
  align-items: stretch;
  margin-top: 5px;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.articleDetailsContainer .inputContainer textarea#articleInformation {
  flex: unset !important;
}

.articleDetailsContainer label {
  cursor: unset;
}
