.articleReviewsMainWrapper {
  border: 1px solid var(--main05);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  flex: 1;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.accordionHeader {
  background-color: rgba(239, 239, 239, 0.3);
  width: 100%;
  padding: 10px;
  border: 0;
  display: flex;
  align-items: center;
  transition: all 200ms;
}

.accordionHeader h3 {
  font-size: 1rem;
  margin-bottom: 0;
}

.accordionBody {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: all 200ms;
  border-bottom: 0px solid var(--main800);
  border-top: 0px solid var(--main800);
}

.reviewAccordionContainer i {
  transition: all 200ms;
  transform: rotate(0deg);
  transform-origin: center;
  margin-right: 5px;
  font-size: 0.8rem;
}
.reviewsAccordionH3 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--main05);
}
.accordionBody > div {
  min-height: 0;
}
.accordionBody > div > div > div {
  margin-top: 10px;
  flex-wrap: wrap;
}
.reviewAccordionContainer .accordionBody > div > div {
  border: none;
}
.reviewAccordionContainer .accordionBody > div > div > div {
  padding: 0px 20px;
}
.accordionBody > div > div:last-child {
  border-bottom: 0;
}

.accordionBody > div > div b {
  min-width: 120px;
  max-width: 120px;
  display: inline-block;
}

.reviewAccordionContainer.open .accordionBody {
  padding: 20px;
  grid-template-rows: 1fr;
  border-bottom: 1px solid var(--main800);
  border-top: 1px solid var(--main800);
}

.reviewAccordionContainer.open:last-child .accordionBody {
  border-bottom: 0;
}

.reviewAccordionContainer:not(:last-child) {
  border-bottom: 1px solid #d3d3d3d3;
}

.reviewAccordionContainer.open i {
  transform: rotate(90deg);
}

.editorComment {
  margin-top: 10px;
  border-top: 1px solid #d3d3d3;
  padding-top: 10px !important;
}
