.emptyResponse i {
    margin-top: 20px;
    font-size: 3rem;
    color: var(--main800);

}

.emptyResponse p {
    margin-top: 20px;
font-size: 1.2rem;
} 


.emptyResponse {
    height: 100%;
}