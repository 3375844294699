.articleAuthorsMainWrapper {
  border: 1px solid var(--main05);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.accordionHeader {
  background-color: rgba(239, 239, 239, 0.3);
  width: 100%;
  border: 0;
  display: flex;
  align-items: center;
  transition: all 200ms;
}

.accordionHeader h3 {
  font-size: 1rem;
  margin-bottom: 0;
}

.accordionBody {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: all 200ms;
  border-bottom: 0px solid var(--main800);
  border-top: 0px solid var(--main800);
}

.articleAuthorContainer i {
  transition: all 200ms;
  transform: rotate(0deg);
  transform-origin: center;
  margin-right: 5px;
  font-size: 0.8rem;
}

.accordionBody > div {
  min-height: 0;
}
.accordionBody > div > div {
  border-bottom: 1px solid #d3d3d3;
  padding: 8px 20px;
}
.accordionBody > div > div:last-child {
  border-bottom: 0;
}

.articleAuthorsMainWrapper .accordionBody > div > div b {
  min-width: 160px;
  display: inline-block;
}

.articleAuthorContainer.open .accordionBody {
  padding: 20px;
  grid-template-rows: 1fr;
  border-bottom: 1px solid var(--main800);
  border-top: 1px solid var(--main800);
}

.articleAuthorContainer.open:last-child .accordionBody {
  border-bottom: 0;
}

.articleAuthorContainer:not(:last-child) {
  border-bottom: 1px solid #d3d3d3d3;
}

.articleAuthorContainer.open i {
  transform: rotate(90deg);
}
