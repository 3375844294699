.pagingButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  gap: 5px;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.pagingButtonsContainer > button {
  background-color: transparent;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--main05);
  outline: transparent;
  transition: all 0.1s ease;
  color: var(--main800);
}

button[disabled] {
  background-color: var(--main01);
  border: 0;
  color: var(--main05);
}

.pagingButtonsContainer > button:not([disabled], .active):hover {
  background-color: var(--main05);
  color: white;
  transform: scale(1.1);
  border: 0;
}

.pagingButtonsContainer > button.active {
  background-color: var(--main800);
  color: white;
}
