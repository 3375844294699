:is(.mainBtn, .modal-footer) .btnRing {
  --uib-speed: 2s;
  vertical-align: middle;
  transform-origin: center;
  animation: rotate var(--uib-speed) linear infinite;
}
:is(.mainBtn, .modal-footer) circle {
  fill: none;
  stroke: white;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: stretch calc(var(--uib-speed) * 0.75) ease-in-out infinite;
}

.modal-footer .mainBtn :is(svg, circle) {
  stroke: var(--main800);
}

.modal-footer {
  gap: 10px;
}
.modal-footer .mainBtn {
  border-radius: 0;
  transition: all 500ms;
  padding: 5px 10px !important;
  border-radius: 5px;
  transition: all 500ms;
  border: 1px solid var(--main05);
  background-color: transparent !important;
  font-size: 15px;
  min-width: 100px;
}
.modal-footer > div button.mainBtn:not(:only-of-type, :last-child) {
  margin-right: 10px;
}

.modal-footer .mainBtn.negative {
  border-color: rgba(255, 0, 0, 0.5);
}

.modal-footer .mainBtn.negative:hover {
  color: white;
  background-color: rgb(225 21 21) !important;
}

.modal-footer .mainBtn[disabled] {
  color: gray;
}

.modal-footer .btn:focus-visible {
  color: black;
}

.modal-footer .mainBtn:hover {
  color: white;
  background-color: var(--main800) !important;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes stretch {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -124px;
  }
}
