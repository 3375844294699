.authContainer input {
  border: 1px solid var(--main800);
}

.authContainer label {
  font-size: 0.9rem;
  font-weight: 500;
}

.authContainer span {
  font-size: 0.8rem;
  color: var(--red);
}

.authLogin input {
  min-width: clamp(15rem, 30vh, 20rem);
}

.authLogin input[type="radio"] {
  min-width: unset;
  max-width: unset;
}

.authContainer > div {
  padding: 20px;
  background-color: white;
}

.authContainer h2 {
  margin-bottom: 0px;
}

.rolesContainer span {
  color: var(--textColor);
}

.rolesContainer > div label {
  align-items: center;
  gap: 5px;
  cursor: pointer;
  user-select: none;
}

@media screen and (max-width: 570px) {
  .authContainer {
    width: 100% !important;
  }
}

.forgotpassword-link {
  color: var(--main800);
  text-decoration: none;
  margin-bottom: -10px;
  margin-top: -5px;
}

.forgotPasswordDiv form p {
  min-width: 30vh;
  max-width: 30vh;
  background: var(--main01);
  padding: 10px;
  border-radius: 5px;
}
