footer {
  color: white;
  background-color: black;
}



footer p {
  margin-bottom: 10px;
}



.copyright {
  font-size: 0.8rem;
  background-color: black;
  color: white;
  font-weight: 100;
  padding: 5px var(--paddingLeftRight);
}


footer img {
  width: 45px;
}